import * as React from "react";

const H2 = ({className, children}) => (
    <h2 className={className}>{children}</h2>
);

H2.defaultProps = {
    className: '',
    children: '',
};

export default H2;