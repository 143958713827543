import * as React from "react";

const H1 = ({className, children}) => (
    <h1 className={className}>{children}</h1>
);

H1.defaultProps = {
    className: '',
    children: '',
};

export default H1;
